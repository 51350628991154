import React from "react";

import accenture from "../img/brands/accenture.png";
import aspenInstitute from "../img/brands/aspen-institute.png";
import deutscheBank from "../img/brands/deutsche-bank.png";
import f10 from "../img/brands/f10.png";
import forbes from "../img/brands/forbes.png";

const Brands = () => {
    return (
        <div className="text-center">
            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-5">
                    <div className="col">
                        <img src={accenture} alt='Accenture' />
                    </div>
                    <div className="col">
                        <img src={forbes} alt='Forbes' />
                    </div>
                    <div className="col">
                        <img src={aspenInstitute} alt='Aspen Institute' />
                    </div>
                    <div className="col">
                        <img src={f10} alt='F10' />
                    </div>
                    <div className="col">
                        <img src={deutscheBank} alt='Deutsche Bank' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brands;