import React from "react";

const Section2Web3 = () => {
    return (
        <div className="container text-center">
            <div className="row">
                <div className="col-10 offset-1 col-md-8 offset-md-2">
                    <h2>We’re in the blockchain game since 2017.</h2>
                    <p className="mono">
                        Over the years, we’ve built our own blockchain products way before the current hype.
                        <br /><br />
                        We’ve won prestigious prizes and have been recognized as a reliable and stable player by institutions and leading-industry companies alike.
                        <br /><br />
                        We now bring our hard-earned expertise to the metaverses, helping brands and exceptional clients who want to explore with us these new exciting territories.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Section2Web3;