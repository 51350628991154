import React from "react";

import fuerteventura from "../img/roadmap/fuerteventura.png";
import barcelona from "../img/roadmap/barcelona.png";
import mars from "../img/roadmap/mars.png";

const Roadmap = () => {
    return (
        <div className="container">
            <div className="text-center">
                <p className="mono">Our company retreats roadmap...</p>
            </div>
            <div className="timeline">
                <div className="row">
                    <div className="col">
                        <div className="timeline-steps aos-init aos-animate">
                            <div className="timeline-step">
                                <div className="timeline-content" title="Fuerteventura">
                                    <p>Fuerteventura</p>
                                    <img className="inner-circle" src={fuerteventura} alt="Fuerteventura" />
                                    <p className="text-muted mt-4 mono">December 2021</p>
                                </div>
                            </div>
                            <div className="timeline-step">
                                <div className="timeline-content" title="Barcelona">
                                    <p>Barcelona</p>
                                    <img className="inner-circle" src={barcelona} alt="Barcelona" />
                                    <p className="text-muted mt-4 mono">May 2022</p>
                                </div>
                            </div>
                            <div className="timeline-step">
                                <div className="timeline-content" title="Mars" >
                                    <p>Mars</p>
                                    <img className="inner-circle" src={mars} alt="Mars" />
                                    <p className="text-muted mt-4 mono">In a near future...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap;