import React, { useState, useEffect } from 'react';

import '../hamburger-icon.css';

const SocialWeb3 = () => {
    return (
        <>
            <div className="social-container">
                <a className="nav-link discord" href="https://discord.com/invite/uxvXj4F4" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="social-icon" d="M298.23,217.56c-.03-.07-.09-.12-.16-.14-7.87-3.61-16.18-6.19-24.71-7.66-.16-.03-.32,.05-.4,.19-1.13,2.05-2.16,4.16-3.08,6.32-9.2-1.4-18.56-1.4-27.75,0-.92-2.16-1.97-4.27-3.12-6.32-.08-.14-.24-.21-.4-.19-8.53,1.47-16.84,4.05-24.71,7.66-.07,.03-.12,.08-.16,.14-15.74,23.5-20.05,46.43-17.94,69.07,.01,.11,.07,.21,.16,.28,9.16,6.79,19.42,11.97,30.31,15.32,.16,.05,.33,0,.43-.14,2.34-3.19,4.42-6.56,6.2-10.08,.1-.19,.02-.42-.17-.52-.01,0-.03-.01-.04-.02-3.27-1.25-6.44-2.76-9.47-4.51-.19-.11-.25-.35-.14-.53,.03-.04,.06-.08,.1-.11,.64-.48,1.27-.97,1.88-1.47,.11-.09,.26-.11,.39-.05,19.87,9.07,41.38,9.07,61.01,0,.13-.06,.29-.04,.4,.05,.61,.5,1.24,1,1.89,1.48,.17,.13,.21,.37,.08,.54-.03,.04-.07,.07-.11,.1-3.03,1.77-6.2,3.27-9.47,4.51-.2,.08-.3,.3-.22,.5,0,.01,.01,.03,.02,.04,1.82,3.51,3.89,6.88,6.2,10.08,.1,.13,.27,.19,.43,.14,10.92-3.34,21.19-8.52,30.36-15.32,.09-.07,.15-.17,.16-.28,2.53-26.18-4.24-48.91-17.95-69.07Zm-62.36,55.28c-5.98,0-10.91-5.49-10.91-12.23s4.83-12.23,10.91-12.23,11.01,5.54,10.91,12.23c0,6.74-4.83,12.23-10.91,12.23Zm40.34,0c-5.98,0-10.91-5.49-10.91-12.23s4.83-12.23,10.91-12.23,11.01,5.54,10.91,12.23c0,6.74-4.79,12.23-10.91,12.23Z" /><polygon class="border-icon" points="417 173.9 417 311.05 394.11 338.1 95 338.1 95 200.95 117.89 173.9 417 173.9" /></svg>
                </a>
            </div>
            {/* <div className="social-container">
                <a className="nav-link twitter" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="social-icon" d="M315.4,219.12c-4.38,1.94-9.06,3.25-14,3.85,5.03-3.02,8.91-7.8,10.72-13.49-4.71,2.79-9.92,4.83-15.48,5.92-4.46-4.76-10.8-7.73-17.8-7.73-13.47,0-24.37,10.93-24.37,24.39,0,1.91,.2,3.78,.63,5.56-20.26-1.01-38.24-10.72-50.25-25.5-2.09,3.6-3.3,7.8-3.3,12.26,0,8.46,4.3,15.93,10.85,20.31-4-.1-7.78-1.21-11.05-3.05v.3c0,11.83,8.41,21.67,19.56,23.91-2.04,.55-4.2,.86-6.42,.86-1.56,0-3.1-.15-4.58-.45,3.1,9.69,12.11,16.74,22.78,16.94-8.33,6.55-18.85,10.45-30.28,10.45-1.96,0-3.9-.13-5.82-.35,10.77,7,23.59,11.03,37.33,11.03,44.86,0,69.38-37.18,69.38-69.43,0-1.06-.03-2.11-.08-3.15,4.76-3.45,8.89-7.75,12.16-12.64Z" /><polygon class="border-icon" points="417 173.9 417 311.05 394.11 338.1 95 338.1 95 200.95 117.89 173.9 417 173.9" /></svg>
                </a>
            </div> */}
        </>
    )
}

const SocialWeb2 = () => {
    return (
        <>
            <>
                <div className="social-container">
                    <a className="nav-link discord" href="https://discord.com/invite/uxvXj4F4" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="social-icon" d="M298.23,217.56c-.03-.07-.09-.12-.16-.14-7.87-3.61-16.18-6.19-24.71-7.66-.16-.03-.32,.05-.4,.19-1.13,2.05-2.16,4.16-3.08,6.32-9.2-1.4-18.56-1.4-27.75,0-.92-2.16-1.97-4.27-3.12-6.32-.08-.14-.24-.21-.4-.19-8.53,1.47-16.84,4.05-24.71,7.66-.07,.03-.12,.08-.16,.14-15.74,23.5-20.05,46.43-17.94,69.07,.01,.11,.07,.21,.16,.28,9.16,6.79,19.42,11.97,30.31,15.32,.16,.05,.33,0,.43-.14,2.34-3.19,4.42-6.56,6.2-10.08,.1-.19,.02-.42-.17-.52-.01,0-.03-.01-.04-.02-3.27-1.25-6.44-2.76-9.47-4.51-.19-.11-.25-.35-.14-.53,.03-.04,.06-.08,.1-.11,.64-.48,1.27-.97,1.88-1.47,.11-.09,.26-.11,.39-.05,19.87,9.07,41.38,9.07,61.01,0,.13-.06,.29-.04,.4,.05,.61,.5,1.24,1,1.89,1.48,.17,.13,.21,.37,.08,.54-.03,.04-.07,.07-.11,.1-3.03,1.77-6.2,3.27-9.47,4.51-.2,.08-.3,.3-.22,.5,0,.01,.01,.03,.02,.04,1.82,3.51,3.89,6.88,6.2,10.08,.1,.13,.27,.19,.43,.14,10.92-3.34,21.19-8.52,30.36-15.32,.09-.07,.15-.17,.16-.28,2.53-26.18-4.24-48.91-17.95-69.07Zm-62.36,55.28c-5.98,0-10.91-5.49-10.91-12.23s4.83-12.23,10.91-12.23,11.01,5.54,10.91,12.23c0,6.74-4.83,12.23-10.91,12.23Zm40.34,0c-5.98,0-10.91-5.49-10.91-12.23s4.83-12.23,10.91-12.23,11.01,5.54,10.91,12.23c0,6.74-4.79,12.23-10.91,12.23Z" /><polygon class="border-icon" points="417 173.9 417 311.05 394.11 338.1 95 338.1 95 200.95 117.89 173.9 417 173.9" /></svg>
                    </a>
                </div>
                {/* <div className="social-container">
                    <a className="nav-link twitter" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="social-icon" d="M315.4,219.12c-4.38,1.94-9.06,3.25-14,3.85,5.03-3.02,8.91-7.8,10.72-13.49-4.71,2.79-9.92,4.83-15.48,5.92-4.46-4.76-10.8-7.73-17.8-7.73-13.47,0-24.37,10.93-24.37,24.39,0,1.91,.2,3.78,.63,5.56-20.26-1.01-38.24-10.72-50.25-25.5-2.09,3.6-3.3,7.8-3.3,12.26,0,8.46,4.3,15.93,10.85,20.31-4-.1-7.78-1.21-11.05-3.05v.3c0,11.83,8.41,21.67,19.56,23.91-2.04,.55-4.2,.86-6.42,.86-1.56,0-3.1-.15-4.58-.45,3.1,9.69,12.11,16.74,22.78,16.94-8.33,6.55-18.85,10.45-30.28,10.45-1.96,0-3.9-.13-5.82-.35,10.77,7,23.59,11.03,37.33,11.03,44.86,0,69.38-37.18,69.38-69.43,0-1.06-.03-2.11-.08-3.15,4.76-3.45,8.89-7.75,12.16-12.64Z" /><polygon class="border-icon" points="417 173.9 417 311.05 394.11 338.1 95 338.1 95 200.95 117.89 173.9 417 173.9" /></svg>
                    </a>
                </div> */}
            </>
        </>
    )
}

const Hamburger = () => {
    return (
        document.querySelector('.animated-icon1').classList.toggle('open')
    )
}

const Navbar = (props) => {
    const [scrollY, setScrollY] = useState(0);

    function logit() {
        if (window.pageYOffset !== 0) {
            setScrollY(1);
        }
        else {
            setScrollY(0);
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    }, [scrollY]);
    return (
        <nav className={"navbar navbar-expand-lg " + (scrollY == 1 ? 'blur' : '')}>
            <div className="container-fluid">
                <button onClick={Hamburger} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="animated-icon1"><span></span><span></span><span></span></div>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav text-center ms-auto mt-5 mt-lg-0">
                        <a className="nav-link p-4" aria-current="page" href="#the-crew">The Crew</a>
                        {(props.version == 'web3'
                            ? <a className="nav-link p-4" href="#what-we-do">What We Do</a>
                            : <a className="nav-link p-4" href="#what-we-do-web2">What We Do</a>
                        )}                     
                        <a className="nav-link p-4" href="https://www.linkedin.com/company/dversostudio/" target="_blank" >Contact</a>
                        <a className="nav-link p-4" href="https://dversostudio.notion.site/DVERSO-Official-Job-Board-e03550d82f754e43aa5b46c10f6f4969" target="_blank">Jobs</a>
                        {(props.version == 'web3'
                            ? <SocialWeb3 />
                            : <SocialWeb2 />
                        )}
                    </div>
                </div>
            </div>
        </nav>
    )
}


export default Navbar;