import React from "react";

import polygon from "../img/web3/polygon.png";
import aave from "../img/web3/aave.png";
import infura from "../img/web3/infura.png";
import alchemy from "../img/web3/alchemy.png";
import sushiswap from "../img/web3/sushiswap.png";
import uniswap from "../img/web3/uniswap.png";
import axie from "../img/web3/axie.png";
import maker from "../img/web3/maker.png";
import opensea from "../img/web3/opensea.png";
import compound from "../img/web3/compound.png";
import rarible from "../img/web3/rarible.png";
import gitcoin from "../img/web3/gitcoin.png";

const Section4Web3 = () => {
    return (
        <div className="text-center">
            <h2>We live and breathe web3.</h2>
            <div className="slide-container mt-5">
                <div class="auto-slider">
                    <img src={polygon} alt="polygon" />
                    <img src={aave} alt="aave" />
                    <img src={infura} alt="infura" />
                    <img src={alchemy} alt="alchemy" />
                    <img src={sushiswap} alt="sushiswap" />
                    <img src={uniswap} alt="uniswap" />
                    <img src={axie} alt="axie" />
                    <img src={maker} alt="maker" />
                    <img src={opensea} alt="opensea" />
                    <img src={compound} alt="compound" />
                    <img src={rarible} alt="rarible" />
                    <img src={gitcoin} alt="gitcoin" />
                    <img src={polygon} alt="polygon" />
                    <img src={aave} alt="aave" />
                    <img src={infura} alt="infura" />
                    <img src={alchemy} alt="alchemy" />
                    <img src={sushiswap} alt="sushiswap" />
                    <img src={uniswap} alt="uniswap" />
                    <img src={axie} alt="axie" />
                    <img src={maker} alt="maker" />
                    <img src={opensea} alt="opensea" />
                    <img src={compound} alt="compound" />
                    <img src={rarible} alt="rarible" />
                    <img src={gitcoin} alt="gitcoin" />
                </div>
            </div>
        </div>
    )
}

export default Section4Web3;