import React, { useState, useEffect } from "react";

const Button = (props) => {

    const [checkButton, setCheckButton] = useState('no-press');
    const pushButton = () => {    
        if (checkButton == 'no-press' || checkButton == 'press-1') {
            setCheckButton('press');
        }
        else{
            setCheckButton('press-1');
        }        
    }
    useEffect(() => {
        for (let i = 0; i < document.querySelectorAll('.button-container').length; i++) {
            document.querySelectorAll('.button-container')[i].classList.remove("press");
            document.querySelectorAll('.button-container')[i].classList.remove("press-1");
            document.querySelectorAll('.button-container')[i].classList.add(checkButton);
        }
    }, [checkButton]);

    return (
        <div onClick={pushButton} className="mt-5 button-container">
            <a href="https://dversostudio.notion.site/DVERSO-Official-Job-Board-e03550d82f754e43aa5b46c10f6f4969" target="_blank">
                <span>{props.text}</span>
                <svg className="svg-shadow" viewBox="0 0 262 62" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 50.5V2H250.5L260.5 13V60H12L2 50.5Z" />
                </svg>
                <svg className="svg-button" viewBox="0 0 262 62" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 50.5V2H250.5L260.5 13V60H12L2 50.5Z" />
                </svg>
            </a>
        </div>
    )
}

export { Button };