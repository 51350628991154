import React from "react";

import penguin1 from "../img/penguins/penguin-1.png";
import penguin2 from "../img/penguins/penguin-2.png";
import penguin3 from "../img/penguins/penguin-3.png";
import penguin4 from "../img/penguins/penguin-4.png";
import penguin5 from "../img/penguins/penguin-5.png";
import penguin6 from "../img/penguins/penguin-6.png";
import penguin7 from "../img/penguins/penguin-7.png";
import penguin8 from "../img/penguins/penguin-8.png";

customElements.define('nft-hexagonal', class extends HTMLElement {
    connectedCallback() {
        let d = `M31 3.5a5 5 90 014 3l8 14a5 5 90 010 5l-8 14a5 5 90 01-4 3h-16a5 5 90 01-4-3l-8-14a5 5 90 010-5l8-14a5 5 90 014-3z`;
        this.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
      <defs><clipPath id="hexagonal"><path fill="none" d="${d}"></clipPath></defs>
      <image clip-path="url(#hexagonal)" height="100%" width="100%" href="${this.getAttribute("penguin")}"></image>
      <path fill="none" stroke="transparent" stroke-width="0" 
              d="${d}"/></svg>`;
    }
});

const Penguins = () => {
    return (
        <div className="container px-5">
            <a name="the-crew"></a>
            <div className="row text-center">
                <div className="col">
                    <h2>Not just a bunch of passionate business-minded nerds 🤓</h2>
                    <h3>💼 Check out our job board for open positions and join the team!</h3>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin1}></nft-hexagonal>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin2}></nft-hexagonal>
                </div>
                <div className="col-12 col-md-4 item">
                    <p className="mt-lg-5">We're a team of highly-skilled individuals who believe that anything can be achieved with the right mindset, innovation-driven methodologies and a fair share of out-of-the-box ideas.</p>
                </div>
                <div className="col-12 col-md-4 item">
                    <p className="mt-lg-5">We're lucky enough to work with exceptional young people who have a strong entrepreneurial drive; some of them launched startups, others come from industry-leading companies and all are aiming for the stars!</p>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin3}></nft-hexagonal>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin4}></nft-hexagonal>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin5}></nft-hexagonal>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin6}></nft-hexagonal>
                </div>
                <div className="col-12 col-md-4 item">
                    <p className="mt-lg-5">We work hard but don't take ourselves too seriously. We trust that well-rounded self-realized individuals deliver the most value and that's why we have fun while we build the most epic products.</p>
                </div>
                <div className="col-12 col-md-4 item">
                    <p className="mt-lg-5">Members of our team have strong finance and technology backgrounds which help us outperform on business and tech-related challenges.</p>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin7}></nft-hexagonal>
                </div>
                <div className="col-6 col-md-4 item">
                    <nft-hexagonal className="nft" penguin={penguin8}></nft-hexagonal>
                </div>
            </div>
        </div>
    )
}

export default Penguins;