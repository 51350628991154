import React from 'react';

import discord from "../img/icons/discord.png";
import twitter from "../img/icons/twitter.png";
import github from "../img/icons/github.png";

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-4 col-lg-3'>
                        <p className='mt-5 fadeInUp'>
                            Become a web3 explorer,
                            <br />
                            <a href='https://twitter.com/TempestoDAO' target="_blank">follow Tempesto 🐧</a> 
                        </p>
                    </div>
                    <div className='col-12 col-md-4 mt-5 mt-md-0 col-lg-3'>
                        <h2 className='mb-3'>Useful stuff</h2>
                        <p className='border-left'>
                            Careers
                            <br /><br />
                            Get a free consultation
                            <br /><br />
                            Chat with us
                        </p>
                    </div>
                    <div className='col-12 col-md-4 mt-5 mt-md-0 col-lg-3'>
                        <h2>Come find us!</h2>
                        <div className='mt-4 social-footer'>
                            {/* <a className='me-2' href='#'>
                                <i>
                                    <img src={twitter} alt="twitter" />
                                </i>
                            </a> */}
                            <a className='me-2' href='https://discord.com/invite/uxvXj4F4' target="_blank">
                                <i>
                                    <img src={discord} alt="discord" />
                                </i>
                            </a>
                            {/* <a className='me-2' href='#'>
                                <i>
                                    <img src={github} alt="github" />
                                </i>
                            </a> */}
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-3'>
                        <p className='mt-5'><span>Made with ❤️ by the DVERSO crew.</span></p>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col text-center'>
                        <p className='legal-line'>© 2022 DVERSO - All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;