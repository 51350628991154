import React from "react";

import web2 from "../img/scene/web2.webp";

import search from "../img/accordion-icon/search.png";
import user from "../img/accordion-icon/user.png";
import sign2 from "../img/accordion-icon/sign2.png";
import code3 from "../img/accordion-icon/code3.png";
import loop from "../img/accordion-icon/loop.png";
import gift from "../img/accordion-icon/gift.png";
import userAdd from "../img/accordion-icon/user_add.png";
import spinner2 from "../img/accordion-icon/spinner2.png";

const Section2Web2 = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <h2>
                        We help our clients create beloved products
                        <br />
                        in the minimum amount of time possible.
                    </h2>
                    <h2 className="mt-4">Our winning recipe 👨‍🍳</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-8 offset-2 col-md-6 offset-md-0 mt-2 pt-2 mt-md-5 pt-md-5">
                    <img style={{borderRadius: "10px"}} className="img-fluid" src={web2} alt="web2" />
                </div>
                <div className="col-12 col-md-6 mt-5">

                    <div className="accordion" id="accordionExample">

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <img className="me-4" src={search} alt="search" /> 1. Analysis of your business needs
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We first get to know you and your business inside out to evaluate the full scope of the project and determine how to allocate time and resources, selecting the right tools and methods to ensure project success.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <img className="me-4" src={user} alt="user" /> 2. User Research & Analysis
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    By thoroughly understanding the different types of users, we start mapping out the different use cases and main features of the solution at hand.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <img className="me-4" src={sign2} alt="sign2" /> 3. Strategic Thinking & Design
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We define the main objectives of the product to inform a clear guiding policy and coherent actions.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <img className="me-4" src={code3} alt="code3" /> 4. Implementation & Development
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Based upon users' feedback analysis, we are able to build scalable MVPs from scratch thanks to our full-stack engineering team and top-notch Agile processes for maximum flexibility.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <img className="me-4" src={loop} alt="loop" /> 5. Iterations & Improvements
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We consider each product as a living object which requirements constantly evolve along the way. That's why we iterate often and improve always as we build to come up with the most-suited solution for your users.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <img className="me-4" src={gift} alt="gift" /> 6. Delivery & Care
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We launch your project alongside of you and keep on fine-tuning the product until full customer satisfation and success.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    <img className="me-4" src={userAdd} alt="userAdd" /> 7. Staff Augmentation
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We know that each project is different and hence, we foster maximum flexibility, acitvating our own network and allocate resources as much as needed with highly-talented people.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <img className="me-4" src={spinner2} alt="spinner2" /> 8. Amplification & Activation
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We don't stop at delivery. We can handle digital promotional activities to ensure product adoption and growth.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section2Web2;