import React from "react";

import { Button } from "./Button";

const Section6 = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-8 offset-2">
                    <h2 className="text-center mb-5">Work from wherever you want, whenever you want.</h2>
                    <p>
                        We are highly flexible and can pretty much work from anywhere. We firmly trust the people we work with and believe in empowering everyone with their own responsabilities. This is why we chose the full-remote work model.
                        <br /><br />
                        Whenever we need to unwind - and this usually happens several times a year - we organize a 2-week company retreat in some of the sunniest and most relaxing European places around. 🌴
                    </p>
                </div>
            </div>

            <Button  text="BECOME DVERSO." />
            
        </div>
    )
}

export default Section6;