import React, { useState } from "react";

import wewear from "../img/projects/wewear.png";
import sinapto from "../img/projects/sinapto.png";
import artshell from "../img/projects/artshell.png";

const Section5Web3 = () => {
    const card = [
        { id: 1, img: wewear, alt: "Wewear", h3: "Wewear", p: "Wewear is changing the clothing sector by providing unique tailor-made 3D-based modelling solutions to their top-tier clients of the fashion industry. They are on a path to invent the ultra personalized NFT. They have chosen us to reach new heights.", a: "Find out more ➝" },
        { id: 2, img: sinapto, alt: "Sinapto", h3: "Sinapto", p: "Sinapto covers high-tech needs and develops tailor-made software solutions for their clients. As a close partner, we participate in building technical solutions which solve sector-specific challenges.", a: "Find out more ➝" },
        { id: 3, img: artshell, alt: "Artshell", h3: "Artshell", p: "Artshell is disrupting how arts is being promoted and showcased. Through their intuitive platform, they act as a matchmaker between artists, collectors and gallerists. We support them to make the most out of the recent opportunities related to NFTs.", a: "Find out more ➝" }
    ];
    const [data, setData] = useState(card);
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="text-center mb-4 pb-4">
                            <h2>We take pride in what we build.</h2>
                            <p className="mono">Over the years, we’ve worked hand-in-hand with future-oriented clients covering a broad range of established and up-and-coming industry sectors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-card-container">
                <div className="container-fluid">
                    <div className="row row-card">
                        {data.map((el) => {
                            const { id, img, alt, h3, p, a } = el;
                            return (
                                <div key={id} className="col col-md-4">
                                    <div className="card h-100">
                                        <img src={img} className="card-img-top" alt={alt} />
                                        <div className="card-body">
                                            <h3 className="card-title">{h3}</h3>
                                            <p className="card-text">{p}</p>
                                        </div>
                                        
                                        {/* <div className="card-body">
                                            <a href="#" className="card-link">{a}</a>
                                        </div> */}
                                        
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section5Web3;