import React, { useState } from "react";

const Section4Web3 = () => {
    const card = [
        { id: 1, h2: "Time to market", p: "We reduce the time of development by using agile methodologies and sprints to push your products on the market as fast as possible." },
        { id: 2, h2: "Scalability", p: "We anticipate your scaling needs, activating all the additional resources and infrastructure in a matter of days." },
        { id: 3, h2: "Resilience", p: "Our development process is siloed so that the machine never stops working if a piece needs to be repaired or debugged - there’s no domino effect with us and no big surprise at delivery." },
        { id: 4, h2: "Simplified deployment", p: "We leverage on existing technologies which have demonstrated reliability and efficiency. In other words, we code clean at maximum speed." },
        { id: 5, h2: "Accessibility", p: "We are transparent and do not hide behing tech smoke mirrors. Every coded bit is self-explanatory so that it enables seamless col laboration even for the most complex solutions." },
        { id: 6, h2: "Open innovation", p: "We resort to adaptive and flexible APIs and the most fit and adapted languages and technologies to your projects." },
    ];
    const [data, setData] = useState(card);
    return (
        <div className="text-center">
            <a name="what-we-do-web2"></a>
            <h2>We aim for the stars with our feet on the ground.</h2>
            <div className="container mt-5">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-5">
                    {data.map((el) => {
                        const { id, h2, p} = el;
                        return (
                            <div key={id} className="col mt-5">
                                <div className="box h-100">
                                    <h2 className="mt-3 mb-4 mono">{h2}</h2>
                                    <p>{p}</p>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default Section4Web3;