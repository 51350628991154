import React, { useState } from "react";

import { Button } from "./Button";

import portfolio123 from "../img/projects/portfolio123.png";
import sinapto from "../img/projects/sinapto.png";
import artshell from "../img/projects/artshell.png";

const Section3Web2 = () => {
    const card = [
        { id: 1, img: portfolio123, alt: "Portfolio123", p: "Portfolio123 is an innovative American fintech company. Through their powerful algorithms, they enable their users to analyze and optimize their stock portfolios. On a daily basis, our studio brings its expertise to improve and tweak the algorithms Portfolio123 has invented and developed." },
        { id: 2, img: sinapto, alt: "Sinapto", p: "Sinapto covers high-tech needs and develops tailor-made software solutions for their clients. As a close partner, we participate in building technical solutions which solve sector-specific challenges." },
        { id: 3, img: artshell, alt: "Artshell", p: "Artshell is disrupting how arts is being promoted and showcased. Through their intuitive platform, they act as a matchmaker between artists, collectors and gallerists. We support them to make the most out of the recent opportunities related to NFTs." }
    ];
    const [data, setData] = useState(card);
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="text-center mb-4 pb-4">
                            <h2>We take pride in what we build.</h2>
                            <p className="mono">Over the years, we’ve worked hand-in-hand with future-oriented clients covering a broad range of established and up-and-coming industry sectors.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slide-card-container">
                <div className="container-fluid">
                    <div className="row" style={{ flexWrap: "unset" }}>
                        {data.map((el) => {
                            const { id, img, alt, p} = el;
                            return (
                                <div key={id} className="col">
                                    <div className="card h-100">
                                        <img src={img} className="card-img-top" alt={alt} />
                                        <div className="card-body">
                                            <p className="card-text">{p}</p>
                                        </div>
                                    </div>

                                    {/* <Button text="Find out more"/> */}

                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section3Web2;