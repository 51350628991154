import React from "react";

import { Button } from "./Button";

const Section1Web3 = () => {
    return (
        <section className="text-center">
            <h1>We're the wanderers of <br className="d-none d-md-block" />the future of tech. 🔮</h1>
            <p className="mono mt-5">We define ourselves as a web3-ready laboratory with extensive experience in blockchain technologies <br />which will help you conquer the metaverses, one piece of land after the other.</p>

            <Button text="Become a pioneer."/>

        </section>
    )
}

const Section1Web2 = () => {
    return (
        <section className="text-center">
            <h1>A <span>comprehensive software house</span> that <br className="d-none d-md-block" />ships killer products over the moon 🌕</h1>
            <p className="mono mt-5">We build the software solutions of tomorrow, from ideation to implementation, <br />our full-stack tech team meets the needs and the ambition of our future-oriented clients.</p>

            <Button text="Let’s talk."/>

        </section>
    )
}

export {
    Section1Web3,
    Section1Web2,
}