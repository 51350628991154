import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from "./components/Navbar";
import { Section1Web3, Section1Web2 } from "./components/Section1";
import Section2Web3 from "./components/Section2Web3";
import Section2Web2 from "./components/Section2Web2";
import Section3Web3 from "./components/Section3Web3";
import Section3Web2 from "./components/Section3Web2";
import Section4Web3 from "./components/Section4Web3";
import Section4Web2 from "./components/Section4Web2";
import Section5Web3 from "./components/Section5Web3";
import Section6 from "./components/Section6";
import Brands from "./components/Brands";
import Penguins from "./components/Penguins";
import Roadmap from "./components/Roadmap";
import Footer from "./components/Footer";

import favicon from "./img/favicon.png";
import faviconWeb2 from "./img/favicon-web2.png";

function App() {
  const [webVersion, setWebVersion] = useState({
    info: 'web3',
    text: 'We’re pretty good at web2 as well.'
  });
  const changeVersion = () => {
    if (webVersion.info === 'web3') {
      setWebVersion({
        info: 'web2',
        text: 'Explore the metaverses.'
      });
    }
    else {
      setWebVersion({
        info: 'web3',
        text: 'We’re pretty good at web2 as well.'
      });
    }
  }

  useEffect(() => {
    document.body.className = webVersion.info;
    if (webVersion.info === "web3") {
      document.querySelector("link[rel~='icon']").href = favicon;
    }
    else {
      document.querySelector("link[rel~='icon']").href = faviconWeb2;
    }
  }, [webVersion.info]);

  function animation() {
    let animations = document.querySelectorAll(".animation");
    for (let i = 0; i < animations.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = animations[i].getBoundingClientRect().top;
      let elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        animations[i].classList.add("active");
      } else {
        animations[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", animation);


  return <>
    <Navbar version={webVersion.info} />
    <main>
      <div className="container">
        <label className="switch">
          <input type="checkbox" onClick={changeVersion} />
          <span className="toggle round"></span>
        </label>
        <span className="switch-text"> {webVersion.text} </span>
      </div>

      {(webVersion.info == 'web3'
        ? <>
          <section className="section1 fadeInUp"><Section1Web3 /></section>
          <section className="section2 animation"><Section2Web3 /></section>
          <section className="brands animation"><Brands /></section>
          <section className="section3 animation"><Section3Web3 /></section>
          <section className="section4 animation"><Section4Web3 /></section>
          <section className="section5 animation"><Section5Web3 /></section>
          <section className="penguins animation"><Penguins /></section>
          <section className="section6 animation"><Section6 /></section>
          <section className="roadmap animation"><Roadmap /></section>
        </>
        : <>
          <section className="section1 fadeInUp"><Section1Web2 /></section>
          <section className="brands animation"><Brands /></section>
          <section className="section2 animation"><Section2Web2 /></section>
          <section className="section3 animation"><Section3Web2 /></section>
          <section className="section4 animation"><Section4Web2 /></section>
          <section className="penguins animation"><Penguins /></section>
          <section className="section6 animation"><Section6 /></section>
        </>
      )}
    </main>
    <Footer />
  </>;
}

export default App;
