import React from "react";
import scena1 from "../img/scene/scena1.webp";
import scena2 from "../img/scene/scena2.webp";
import scena3 from "../img/scene/scena3.webp";

const Section3Web3 = () => {
    return (
        <div className="container">
            <a name="what-we-do"></a>
            <div className="row">
                <div className="col-10 offset-1">
                    <div className="text-center mb-5 pb-5">
                        <p className="mono">We build next-gen tech and blockchain products from ideation to implementation and beyond.
                            We navigate through web3, keeping users at the core of everything we do.
                        </p>
                    </div>
                </div>
            </div>
            <div id="carousel1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carousel1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carousel1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carousel1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="container">
                            <div className="row">
                                <div className="col-6 offset-3 col-lg-5 offset-lg-0 col-xl-5 offset-xl-1">
                                    <img src={scena1} className="d-block w-100" alt="scena" />
                                </div>
                                <div className="col-12 col-lg-7 col-xl-4 offset-xl-1 item-text">
                                    <h3 className="mt-5 mt-lg-0">Launch meaningful NFT projects</h3>
                                    <p>
                                        NFT projects are buzzing right now and everyone wants a piece of it.
                                        <br /><br />
                                        But there’s a difference between well thought-out NFT endeavours and empty meaningless ones.
                                        <br /><br />
                                        When done the right way, with a clear roadmap and true added value for the community, NFT projects can become one of the strongest assets for your brand.
                                        <br /><br />
                                        You’ve just to think long-term and know the intricate dynamics of the metaverses to make it count.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-6 offset-3 col-lg-5 offset-lg-0 col-xl-5 offset-xl-1">
                                <img src={scena2} className="d-block w-100" alt="scena" />
                            </div>
                            <div className="col-12 col-lg-7 col-xl-4 offset-xl-1 item-text">
                                <h3 className="mt-5 mt-lg-0">Build your office in the metaverses</h3>
                                <p>
                                    Very much like in the real world, pieces of land can be bought or rented on the metaverses.
                                    <br /><br />
                                    At Dverso, we call it virtual estate.
                                    <br /><br />
                                    We guide you through all the available options and select the right metaverse(s) for you before conceiving and implementing an outstanding web3 office.
                                    <br /><br />
                                    It will soon become a powerful way to assert your presence in the virtual realms, unlocking new leads and potential income streams.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row">
                            <div className="col-6 offset-3 col-lg-5 offset-lg-0 col-xl-5 offset-xl-1">
                                <img src={scena3} className="d-block w-100" alt="scena" />
                            </div>
                            <div className="col-12 col-lg-7 col-xl-4 offset-xl-1 item-text">
                                <h3 className="mt-5 mt-lg-0">Create your own token</h3>
                                <p>
                                    Ever heard about cryptos?
                                    <br /><br />
                                    At Dverso, we truly believe cryptocurrencies are going to change the world for the better and we’ve been in this game since 2016.
                                    <br /><br />
                                    Thanks to our skilled team of crypto experts, we help you design and launch your own cryptocurrency.ies to make your wildest dreams come true.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Section3Web3;